import { render, staticRenderFns } from "./SubmissionDocument.vue?vue&type=template&id=508ecefe&scoped=true"
import script from "./SubmissionDocument.vue?vue&type=script&lang=js"
export * from "./SubmissionDocument.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508ecefe",
  null
  
)

export default component.exports